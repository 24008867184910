import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter06 from '../common/src/assets/image/charity/specter/kapitola_06_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Šiesta kapitola: Vzbura proti Bohu | Ako duch komunizmu vládne nášmu svetu"
           description="Šiesta kapitola: Vzbura proti Bohu"
           image={imageChapter06}/>
      <h1>Šiesta kapitola: Vzbura proti Bohu</h1>
      <h2>Úvod</h2>
      <p>Takmer všetky národy sveta majú svoje vlastné starodávne mýty a legendy, ktoré hovoria o stvorení človeka na
        obraz Boží. Tieto tradičné viery ustanovujú pre ľudí základy morálky a kultúry a zanechávajú cestu k návratu do
        neba pre tých, ktorí vo svojich Bohov veria. Na Východe i na Západe existujú legendy o tom, ako Nü-wa a Jehova
        stvorili svojich ľudí.</p>
      <p>Nebesia tiež človeka varujú, aby nasledoval Božie prikázania, inak bude čeliť odplate. V časoch rozsiahleho
        morálneho úpadku Nebesia človeka zničia, aby zachovali čistotu vesmíru. Mnohé rasy na svete majú legendy o tom,
        že určité civilizácie boli zničené veľkými povodňami. Hovorí sa, že bájna civilizácia Atlantídy sa behom noci
        ponorila do hlbín mora.</p>
      <p>Aby zachovali morálku ľudí, osvietené bytosti či proroci sa v určitých obdobiach narodia v ľudskom svete, aby
        napravili ľudské srdcia a viedli civilizácie k rozvoju a vyspelosti. Medzi takýchto prorokov patrili</p>
      <p>Mojžiš a Ježiš na Blízkom Východe, Lao-c‘ v Číne, Šákjamúni v Indii a Sokrates v starovekom Grécku.</p>
      <p>Ľudské dejiny a kultúra pomáhajú ľuďom pochopiť, kto je to Budha, Tao a Boh, čo to znamená veriť v Boha a ako
        kultivovať svoje vnútro. Rôzne školy praktizovania učia, čo je spravodlivé a čo je zlé, ako rozpoznať pravdu od
        lži a dobro od zla. Zároveň človeka učia, aby pred koncom sveta očakával návrat Stvoriteľa na Zem. Iba tak môže
        byť zachránený a vrátiť sa do neba. Keď ľudia prerušia svoje spojenie s Bohom, ktorý ich stvoril, ich morálka
        rýchlo upadne. Úpadok morálky nakoniec povedie k zániku civilizovaného života.</p>
      <p>Na Východe, najmä v starovekej Číne, je viera silno zakorenená v srdciach ľudí prostredníctvom tradičnej
        kultúry, ktorá sa odovzdávala po tisícročia. Preto nie je ľahké oklamať čínskych ľudí jednoduchými lžami, aby
        prijali ateizmus. Aby odstránil 5000 rokov pretrvávajúcu kultúru a vieru, komunistický duch použil nevídané
        násilie. Zabil elity, ktoré zdedili tradičnú kultúru a následne použil lži, aby oklamal generáciu za generáciou
        mladých ľudí.</p>
      <p>Na Západe a v iných častiach sveta sú náboženstvo a viera hlavnými formami, ktorými človek udržiava kontakt s
        Bohmi, a sú tiež dôležitými základnými piliermi pre zachovanie morálnych noriem. Hoci duch komunizmu nedokázal v
        západných krajinách nastoliť komunistickú tyraniu, dosiahol svoj cieľ – zničil pravoverné náboženstvá a skazil
        ľudské bytosti pomocou podvodu, odchýlenia sa a infiltrácie.</p>
      <h2>Na Východe: Násilná vzbura proti Bohu</h2>
      <h3 data-mce-word-list="1">a)&nbsp;&nbsp;&nbsp; Ako Sovietsky zväz násilím zničil pravoverné náboženstvá</h3>
      <p><em>Komunistický manifest</em> vyzýva na zničenie rodiny, cirkvi a národného štátu. Je zrejmé, že rozvrátenie a
        odstránenie náboženstva je jedným z dôležitých cieľov komunistickej strany.</p>
      <p>Karl Marx pôvodne veril v Boha, stal sa však vyznávačom Satana. Nepochybne teda dobre vedel o existencii Boha a
        diabla. Bolo mu tiež jasné, že ľudia – a predovšetkým veriaci – by mali problém prijať neprikrášlené démonické
        učenia. Preto od začiatku obhajoval ateizmus a vyhlasoval, že „náboženstvo je ópiom ľudstva“, že „od samého
        začiatku je východiskovým bodom komunizmu ateizmus“ a podobne.<a href="#_edn1" id="_ednref1">[1]</a></p>
      <p>Ak ľudia neveria v Boha, diabol sa môže zmocniť ich duše a skaziť ju a nakoniec môže ľudí zatiahnuť do pekla.
        Preto sa v internacionále, komunistickej hymne, spieva, že nie sú žiadni spasitelia – žiaden Boh ani ľudskí
        vládcovia – na ktorých by sme sa mohli spoľahnúť. Marx očiernil náboženstvá a vieru v Boha na teoretickej
        úrovni, zatiaľ čo Lenin potom čo sa v roku 1917 chopil moci, na ne zaútočil pomocou celoštátneho aparátu. Aby
        Lenin prinútil ľudí opustiť Boha, potláčal pravoverné náboženstvá a spravodlivú vieru pomocou násilia a iných
        agresívnych taktík.</p>
      <p>V roku 1919 predstavil Lenin nový program strany, ktorý obsahoval rozsiahle odstraňovanie náboženstva. V roku
        1922 vydal tajné uznesenie, ktoré stanovovalo, že cirkvám a náboženským inštitúciám musí byť „s nemilosrdným
        odhodlaním, bez akýchkoľvek pochybností a v čo najkratšom čase“ odobraný hodnotný majetok vrátane drahokamov.
        Prehlásil: „Čím viac reakcionárskeho duchovenstva a reakcionárskej buržoázie pri tejto príležitosti postrieľame,
        tým lepšie, pretože toto ,publikum’ musí dostať také ponaučenie, že sa po niekoľko ďalších desaťročí neodváži
        pomyslieť na akýkoľvek odpor.“<a href="#_edn2" id="_ednref2">[2]</a></p>
      <p>V nasledujúcich rokoch prišlo k rozsiahlemu rabovaniu cirkevného majetku, kostoly a kláštory boli uzavreté a
        veľký počet pravoslávnych a katolíckych duchovných skončil na popravisku.</p>
      <p>Po Leninovej smrti pokračoval v jeho praktikách Stalin a&nbsp;30. rokoch 20. storočia začal s extrémne krutými
        čistkami. Stalin nariadil, aby celá krajina zrealizovala „Päťročný ateistický plán“. Tvrdil, že na konci tejto
        päťročnice bude zatvorený posledný kostol, zneškodnený posledný kňaz a Sovietsky zväz sa stane úrodnou pôdou pre
        komunistický ateizmus, v ktorom už nezostane ani stopa po náboženstve. V tridsiatych rokoch boli zatknuté a
        umučené stovky tisíc kňazov. V roku 1941 bolo pre verejnosť otvorených iba 4 225 pravoslávnych kostolov, zatiaľ
        čo predtým, ako sa sovieti dostali k moci, ich bolo viac ako 46 000. Bolo zničených 97 % pravoslávnych kláštorov
        a zostalo ich len 37. Kultúrne elity a intelektuáli boli počas tohto obdobia poslaní do gulagu alebo boli
        zastrelení.</p>
      <p>Aby Stalin počas druhej svetovej vojny dokázal vzdorovať Nemecku, naoko pozastavil prenasledovanie pravoslávnej
        a katolíckej cirkvi. Chcel totiž využiť ich finančné zdroje a pracovnú silu. Tým vyvolal dojem, že by mohol
        neskôr tieto náboženstva znovu rehabilitovať. V hlave sa mu však rodil omnoho zvrátenejší plán: chcel nad
        obnovenou pravoslávnou a katolíckou cirkvou získať pevnú kontrolu tým, že duchovných podrobí vláde komunistickej
        strany, aby tak podkopal tradičnú vieru. Z náboženstva sa tak stal nástroj komunistického ducha na klamanie a
        ovládanie verejnosti, najmä veriacich, ktorých náboženská viera bola príliš silná na to, aby ju zničilo zjavné
        prenasledovanie.</p>
      <p>V roku 1961 bol duchovný Alexej II. vo vtedajšom Sovietskom zväze povýšený na biskupa Tallinu a Estónska, v
        roku 1964 sa stal arcibiskupom a v roku 1968 metropolitom. V roku 1990, pred rozpadom Sovietskeho zväzu, sa stal
        moskovským patriarchom. Potom, čo sa Sovietsky zväz rozpadol, odtajnené archívy KGB odhalili, že Alexej II.
        pracoval pre KGB, tajnú službu Sovietskeho zväzu.</p>
      <p>Alexej II. sa neskôr priznal, že zradil svoje princípy a&nbsp;pôsobil ako sovietsky agent. Svoju ľútosť
        otvorene prejavil v rozhovore pre denník <em>Izvestia </em>v roku 1991: „Ak ste chceli obhajovať jednu vec, bola
        nevyhnutná obeť niekde inde. Aj iné organizácie a ľudia v tej dobe predsa museli niesť zodpovednosť nielen za
        seba, ale aj za tisíce ďalších osudov – kto v tých rokoch v Sovietskom zväze nebol prinútený konať rovnako?
        Avšak pred ľuďmi, ktorým v tých rokoch kompromisy, ticho, nútená pasivita alebo prejavy lojality zo strany
        vodcov cirkvi spôsobili bolesť, pred týmito ľuďmi, a nielen pred Bohom, žiadam o odpustenie, porozumenie a
        modlitby.“<a href="#_edn3" id="_ednref3">[3]</a></p>
      <p>Sovietsky zväz nezanechal toto znehodnotené náboženstvo iba na svojom území, ale systematicky rozšíril svoj
        zhubný vplyv i do celého sveta.</p>
      <h3>b) Ako Čínska komunistická strana zničila kultúru a náboženstvo</h3>
      <h4>Ničenie tradičnej čínskej kultúry</h4>
      <p>Čína má najstaršiu civilizáciu na svete s historickými záznamami siahajúcimi 5000 rokov dozadu. Čína bola
        nazývaná „Nebeskou ríšou“. Jej nádherná a veľkolepá tradičná kultúra si získala úctu mnohých národov. Čínska
        kultúra hlboko ovplyvnila celú východoázijskú oblasť a viedla k vytvoreniu celej sféry čínskej civilizácie.
        Otvorenie Hodvábnej cesty a šírenie štyroch veľkých vynálezov (výroby papiera, kompasu, strelného prachu a
        tlače) na Západ, pomohlo urýchliť vývoj európskej civilizácie.</p>
      <p>Hoci v Číne neexistuje jedno prevládajúce náboženstvo, ako je tomu v ostatných krajinách, Číňania majú tiež
        pevnú vieru v božstvá a Budhu a náboženská viera tvorí základ tradičnej čínskej kultúry. Konfucianizmus,
        budhizmus, taoizmus a dokonca aj západné náboženstvo existovali spoločne v Číne po tisíce rokov v mieri.</p>
      <p>Komunizmus chcel túto starovekú kultúru zničiť, no nikdy by to nemohol dosiahnuť jednoducho tým, že by oklamal
        čínsky národ, aby sa svojej kultúry vzdal. Preto ČKS použila všemožné zlé taktiky nadobudnuté počas desaťročí
        neustálych politických kampaní a začala masovým vyvražďovaním. ČKS pracovala na podkopaní podstaty náboženstva,
        prenasledovala intelektuálov a zničila hmotnú kultúru, ako chrámy, kultúrne pamiatky, starobylé maľby a
        staroveké relikvie.</p>
      <p>Počas histórie komunistickej vlády v Číne získala strana vďaka nepretržitým politickým kampaniam,
        prenasledovaniu a masovému zabíjaniu nevídané poznatky o tom, ako používať propagandu, teror, ekonomické záujmy
        a ďalšie taktiky na ovládnutie ľudí. Zatiaľ čo ničila tradičnú kultúru, zároveň zakladala kultúru strany, ktorou
        skazila celé generácie Číňanov.</p>
      <p>Zaplavení zlou kultúrou komunistickej strany, ktorej povahou je podvádzať, škodiť a bojovať, stratili milióny
        Číňanov pochopenie univerzálnych hodnôt, ktoré sa v čínskej civilizácii utvárali po tisícročia. Tieto zvrátené
        veci usporiadal duch komunizmu, aby sa pripravil na poslednú bitku v ľudskom svete medzi dobrom a zlom.</p>
      <p>Statkári a šľachta na vidieku, a obchodníci a učenci v&nbsp;mestách, patrili medzi elitu, ktorá bola hlavným
        nositeľom tradičnej čínskej kultúry. Potom, čo sa komunistická strana dostala k moci v roku 1949, použila hneď
        od začiatku sériu hnutí na zmasakrovanie statkárov a šľachty na vidieku a kapitalistov v&nbsp;mestách.
        Ukoristila tak spoločenské bohatstvo, zatiaľ čo vytvárala teror. Súčasne s tým „ideologicky reformovala“ učencov
        – indoktrinovala ich materializmom, ateizmom a teóriou evolúcie – aby systematicky vymývala mozog novej
        generácii študentov a vštepila im nenávisť k tradičnej kultúre.</p>
      <p>V rámci „hnutia proti pravičiarom“ v 50. rokoch 20. storočia boli všetci neposlušní intelektuáli vyhnaní a
        odsúdení na prevýchovu prostredníctvom nútených prác, čo ich uvrhlo na samé dno spoločnosti. Strana si tak z
        učencov urobila terč posmechu a zosmiešňovania. Odstránením tradičných elít sa tiež prerušil generácie
        pretrvávajúci proces dedenia a odovzdávania tradičnej čínskej kultúry. Nastupujúca mladá generácia už nebola
        prostredníctvom rodiny, školy, spoločnosti a obce kultivovaná a vychovávaná v tejto kultúre – a tak sa stala
        generáciou bez tradičnej kultúry.</p>
      <p>Po antipravičiarskom hnutí zostalo iba zopár nezávislých hlasov. ČKS však i napriek tomu stále nebola spokojná.
        Koniec-koncov, starí ľudia si ešte pamätali tradičnú kultúru a jej hmotné prvky a staré artefakty a
        architektúra, boli všade. Navyše, umenie stále nieslo tradičné hodnoty. Komunistická strana v roku 1966 zahájila
        hnutie zamerané na zničenie tradičnej kultúry v širokom rozsahu – spustila „kultúrnu revolúciu“. S využitím
        študentov, ktorým po založení Čínskej ľudovej republiky vymyla mozog, vzbudila v mladých ľuďoch nepokoj a
        rebéliu a spustila kampaň na zničenie „štyroch prežitkov“ (starých myšlienok, kultúry, obyčajov a zvyklostí),
        aby do tradičnej čínskej kultúry vniesla zmätok.</p>
      <p>Pekelný požiar tejto kampane sa rozhorel po celej Číne. Boli zničené kláštory, chrámy, budhistické sochy,
        obrazy a kultúrne pamiatky, do bodu, že obnova už nebola možná. Pred kultúrnou revolúciou boli starodávne
        artefakty v Číne v každom veľkom i malom meste. Len pár desiatok centimetrov pod zemou sa nachádzali artefakty z
        nedávnej histórie; v hĺbke jedného až šiestich metrov sa potom nachádzali nespočetné artefakty z
        predchádzajúcich dynastií. Táto kampaň nielenže zničila miesta náboženskej viery, modlitby a kultivácie –
        staroveké miesta, ktoré stelesňovali súlad človeka s Nebesami – ale odstránila tiež z ľudských sŕdc základnú
        spravodlivú vieru ako je viera v harmóniu medzi človekom a vesmírom.</p>
      <p>Navyše, aby ČKS odrezala spojenie Číňanov s ich históriou a&nbsp;Bohmi, aktívne urážala predkov čínskeho národa
        a očierňovala tradičnú kultúru. Krajiny si zvyčajne svojich predkov a kráľov v histórii vážia a cenia si svoje
        tradície. Avšak v očiach ČKS boli cisári, generáli, učenci a talentovaní ľudia starovekej Číny len neschopnými
        úbožiakmi. Takéto urážanie vlastných predkov naozaj nemá v histórii obdobu. Pod vedením ČKS Číňania zatratili
        Boha, odvrhli svojich predkov a zničili svoju vlastnú kultúru, čím sa vydali po nebezpečnej ceste.</p>
      <h4>Prenasledovanie náboženstiev</h4>
      <p>Potom, čo sa ČKS dostala k moci, nasledovala prístup Sovietskeho zväzu pri odstraňovaní náboženstiev. Na jednej
        strane podporovala ateizmus a spustila ideologické útoky proti náboženskej viere. Na strane druhej v sérii
        politických hnutí potláčala a zabíjala praktizujúcich náboženskej viery. Perzekúcie ľudí praktizujúcich
        pravoverné náboženstvá boli čím ďalej krutejšie, až vyvrcholili v roku 1999, kedy bolo spustené krvavé
        prenasledovanie duchovnej praxe Falun Gong.</p>
      <p>Krátko potom, čo si ČKS uzurpovala moc v roku 1949, zakázala náboženské stretnutia a spálila nespočetné
        množstvo výtlačkov Biblie i písma iných náboženstiev. Požadovala, aby sa kresťania, katolíci, taoisti a budhisti
        zaregistrovali vo vládnych organizáciách a vyjadrili ľútosť nad svojimi „chybami“. Tých, ktorí odmietli,
        postihol tvrdý trest. V roku 1951 komunistická strana otvorene prehlásila, že tí, ktorí budú naďalej navštevovať
        náboženské zhromaždenia, budú popravení alebo uväznení na doživotie. Mnoho budhistických mníchov bolo vyhnaných
        z chrámov a boli prinútení žiť a pracovať vo svetskom prostredí. Katolícki a kresťanskí kňazi boli uväznení a
        mučení. Veriacich popravovali alebo posielali na prevýchovu nútenými prácami. Podľa neúplných štatistík boli v
        prvých rokoch nástupu ČKS k moci zatknuté alebo popravené takmer tri milióny stúpencov a členov náboženských
        organizácií.</p>
      <p>ČKS na posilnenie svojej moci nad náboženstvom zriadila – podobne ako Komunistická strana Sovietskeho zväzu
        (KSSZ) – pre každú náboženskú skupinu regulačný orgán, ako napríklad Čínsku taoistickú asociáciu, Čínsku
        budhistickú asociáciu a podobne. Na kontrolu katolíkov založila Čínsku vlasteneckú katolícku asociáciu. Všetky
        náboženské združenia boli nútené nasledovať nariadenia strany, ktorá „myšlienkovo ​​reformovala“ svojich členov.
        ČKS tieto združenia zároveň použila, aby vykonala to, čo zlý duch nemohol vykonať priamo: zasiatie rozbrojov a
        skorumpovanie pravoverných náboženstiev zvnútra.</p>
      <p>Podobne, potom ako ČKS v roku 1950 vyslala vojenské jednotky, aby obsadili Tibet, začala tvrdo prenasledovať
        tibetský budhizmus. 14. tibetský dalajláma v roku 1959 utiekol do exilu v Indii, čo ČKS považovala za vzburu.
        10. pančenláma v máji 1962 predložil Štátnej rade ČĽR sťažnosť o tom, ako strana pomocou čínskej armády sabotuje
        tibetskú kultúru a budhistické tradície:</p>
      <p><em>Pokiaľ ide o likvidáciu budhistických sôch, budhistických písiem a budhistických stúp, v podstate okrem
        veľmi malého počtu kláštorov, vrátane štyroch veľkých chrámov, ktoré boli zachránené, v ostatných kláštoroch v
        Tibete a na dedinách, v malých mestách a v mestách v rozsiahlych poľnohospodárskych oblastiach, niektorí z
        našich kádrov z oblasti Chan vytvorili plán, naše tibetské kádre sa zmobilizovali a niektorí ľudia medzi
        aktivistami, ktorí nerozumeli dôvodu, sa sami stali vykonávateľmi plánu. </em></p>
      <p><em>Postavili sa do čela más a vyvolali veľkú vlnu na odstránenie sôch Budhu, budhistických písiem a stúp.
        Hodili ich do vody, sotili ich na zem, rozbili ich a roztavili. Bezohľadne, divoko a unáhlene ničili kláštory,
        budhistické sály, steny „mani“, stúpy a zo sôch Budhu ukradli veľa ozdôb a odcudzili aj vzácne veci z
        budhistických stúp. </em></p>
      <p><em>Pretože vládne obstarávacie orgány nerobili rozdiely pri kúpe neželezných kovov, odkupovali mnoho sôch
        Budhu, stúp a ponúkaných nádob vyrobených z neželezných kovov a týmto ukazovali, že podporujú ničenie týchto
        vecí. Výsledkom bolo, že niektoré dediny a kláštory nevyzerali, ako keby ich ľudia zničili úmyselne, ale akoby
        bolo tesne po vojne, akoby boli zničené bombardovaním, a nedalo sa na to pozerať. </em></p>
      <p><em>Navyše nehanebne hanobili náboženstvo, používali „Tripitaku“ ako materiál na hnojenie, a používali obrázky
        Budhov a budhistické sútry na výrobu topánok. Toto bolo úplne proti akémukoľvek zdravému rozumu. Pretože robili
        veľa vecí, ktoré by sa dokonca ani šialenci neodvážili robiť, ľudia zo všetkých spoločenských vrstiev boli úplne
        šokovaní a celkom zmätení, cítili sa nesmierne znechutení a deprimovaní. Kričali so slzami v očiach: „Naša
        oblasť sa premenila na temné miesto“, a bolo počuť aj iné podobné žalostné náreky.<a href="#_edn4"
                                                                                             id="_ednref4">[4]</a></em>
      </p>
      <p>Po zahájení kultúrnej revolúcie v roku 1966 boli mnohí lámovia prinútení žiť svetským životom a bolo spálené
        veľké množstvo drahocenných písiem. V Tibete pretrvalo do roku 1976 iba osem z pôvodných 2 700 chrámov. Bol tiež
        vyplienený chrám Džókhang, najdôležitejší chrám v&nbsp;Tibete, ktorý bol postavený pred viac ako 1300 rokmi –
        pred dynastiou Tchang.<a href="#_edn5" id="_ednref5">[5]</a></p>
      <p>V Číne má kultivácia taoizmu dlhú históriu. Pred viac ako 2500 rokmi zanechal Lao-c’ ľuďom spis Tao-te-ťing,
        pozostávajúci z 5000 znakov, v ktorom vyložil podstatu taoistickej kultivácie. Tao-te-ťing sa nerozšíril iba vo
        východných krajinách. Mnohé západné krajiny ho tiež preložili do svojich jazykov. Avšak počas kultúrnej
        revolúcie bol Lao-c’ kritizovaný a označený za pokrytca a Tao-te-ťing bol považovaný za&nbsp; „feudálny
        prežitok“.</p>
      <p>Jadrom učenia konfucianizmu sú láskavosť, poctivosť, morálne dispozície k robeniu dobra, náležité správanie,
        múdrosť a dôvera. Konfucius stanovil morálne normy pre celé generácie. Počas kultúrnej revolúcie viedli
        povstalci v Pekingu Červené gardy k Čchü-fu, rodnému mestu Konfucia, kde ničili a pálili staré knihy a rozbili
        tisíce historických náhrobkov, vrátane Konfuciovho. V roku 1974 Čínska komunistická strana spustila ďalšie
        hnutie na „kritizovanie Lin Piaoa a kritizovanie Konfucia“. Tradičné konfuciánske zmýšľanie ohľadom toho, ako by
        človek mal žiť a aké morálne normy by mal zastávať, považovala strana za bezcenné.</p>
      <p>Ešte brutálnejším a tragickejším sa stalo prenasledovanie, ktoré v júli 1999 spustil vtedajší vodca strany
        Ťiang Ce-min voči Falun Gongu (známemu tiež ako Falun Dafa) a jeho kultivujúcim, ktorí veria v pravdivosť, súcit
        a znášanlivosť.</p>
      <p>Ťiangov politický vzostup prišiel hneď po masakre na Námestí nebeského pokoja, 4. júna 1989. Po smrti
        najvyššieho vodcu Teng Siao-pchinga v roku 1997 prevzal Ťiang plnú moc, vytlačil ďalších vysokých predstaviteľov
        a vybudoval si opevnenú sieť podporovateľov. V roku 1999, s využitím dobre vyvinutých bezpečnostných síl ČĽR a
        propagandistickej mašinérie, spustil Ťiang prenasledovanie Falun Gongu, ktorý mal v&nbsp;tom čase odhadovaných
        sto miliónov nasledovníkov. Táto celonárodná kampaň štátneho teroru – najväčšia od dôb kultúrnej revolúcie –
        posilnila Ťiangovu politickú autoritu a umožnila mu postaviť svojich spojencov do pozície, ktorá im priniesla
        moc a zisk. Ťiangova brutálna politika proti Falun Gongu a korupcia, ktorú podporoval, položili základy pre
        obnovenie totalitarizmu ČKS a priviedli Čínu k bezprecedentnému morálnemu pádu.</p>
      <p>Navyše strana do dnešného dňa vykonáva odoberanie orgánov praktizujúcim Falun Gongu zaživa, čo je zločin, ktorý
        na tejto planéte nemá v histórii obdobu.</p>
      <p>ČKS počas niekoľkých málo desaťročí úplne zdevastovala tisícky rokov pretrvávajúcu tradičnú čínsku kultúru,
        morálne hodnoty a tradíciu sebazušľachťovania. V dôsledku toho ľudia už viac neveria v Bohov a odvrátili sa od
        nich. Pociťujú&nbsp; duchovnú prázdnotu a ich morálne hodnoty boli narušené.</p>
      <h2>2. Na Západe: Infiltrácia a obmedzovanie náboženstva</h2>
      <p>Komunizmus sa tiež systematicky pripravoval útok na veriacich v nekomunistických krajinách. Prostredníctvom
        sovietskej a čínskej komunistickej strany použil peniaze a špiónov, aby prenikol do náboženských inštitúcií
        cudzích krajín pod zámienkou „náboženskej výmeny“. Týmto spôsobom pokrivil spravodlivé viery, alebo ich dokonca
        priamo napadol, a zaviedol do náboženstva prvky socialistickej a komunistickej ideológie. To viedlo k tomu, že
        veriaci potom praktizovali náboženstvá, ktoré boli nenávratne pozmenené komunistickou ideológiou.</p>
      <h3 data-mce-word-list="1">a)&nbsp;&nbsp;&nbsp; Infiltrácia náboženstva</h3>
      <p>Marxisti v Spojených štátoch infiltrovali kresťanské cirkvi a prenikli na semináre, kde zvádzali z cesty jednu
        skupinu kňazov a pastorov za druhou. Tí potom ovplyvňovali náboženstvo v širšej miere po celej</p>
      <p>krajine.</p>
      <p>Vo výpovedi, ktorú v júli 1953 predniesol pred Výborom pre neamerickú činnosť Manning Johnson, vysoko postavený
        člen komunistickej strany, povedal:</p>
      <p><em>„Potom, čo sa Kremeľ uzniesol na taktike infiltrovania náboženských organizácií, spôsob, akým túto ,novú
        líniu‘ vykonával, bolo nasledovanie skúseností živého cirkevného hnutia v Rusku, kde komunisti zistili, že
        zničenie náboženstva sa dá veľmi urýchliť prostredníctvom infiltrácie cirkvi komunistickými agentami pôsobiacimi
        v samotnej cirkvi. ...</em></p>
      <p><em>Vo všeobecnosti išlo o to, aby sa odklonilo myslenie kňazov od duchovných tém k&nbsp;témam materiálnym a
        politickým </em><em>–</em><em> politickými sa samozrejme myslela politika založená na komunistickej doktríne
        dobývania moci. Namiesto zdôrazňovania duchovných vecí a záležitostí duše sa nanovo kládol silný dôraz na
        riešenie tých záležitostí, ktoré zväčša viedli ku komunistickému programu tzv. „naliehavých požiadaviek“. Tieto
        sociálne požiadavky boli samozrejme takej povahy, že boj za ne by oslabil našu súčasnú spoločnosť a pripravil ju
        na konečné dobytie komunistickými silami.“<a href="#_edn6" id="_ednref6">[6]</a></em></p>
      <p>Po rozsiahlom výskume archívov bulharskej komunistickej strany z obdobia studenej vojny odhalil bulharský
        historik Momchil Metodiev skutočnosť, že východoeurópska komunistická spravodajská sieť úzko spolupracovala s
        náboženskými výbormi strany, aby ovplyvňovala a infiltrovala medzinárodné náboženské organizácie.<a
          href="#_edn7" id="_ednref7">[7]</a></p>
      <p>V celosvetovom meradle bola komunizmom z východnej Európy výrazne infiltrovaná organizácia Svetovej rady cirkví
        (SRC). SRC je celosvetová kresťanská organizácia, ktorá bola založená v roku 1948. Medzi jej členmi je väčšina
        hlavných kresťanských cirkví, ktoré zastupujú približne 590 miliónov ľudí zo 150 krajín. SRC je tak hlavnou
        silou vo svetových náboženských kruhoch. SRC však bola prvou medzinárodnou náboženskou organizáciou, ktorá počas
        studenej vojny za svojich členov prijala komunistické krajiny a prijala od nich finančnú podporu.</p>
      <p>Historik a profesor Cambridgeskej univerzity, Christopher Andrew, na základe zverejneného spisu KGB z roku 1969
        napísal, že počas studenej vojny pracovalo päť agentov KGB v Ústrednom výbore SRC, kde uplatňovali skrytý vplyv
        na politiku a činnosť SRC. Zverejnený spis KGB z roku 1989 ukazuje, že títo agenti, ktorých riadila KGB
        zaisťovali, že výbor vydáva verejné správy, ktoré sú v súlade s cieľmi socializmu.<a href="#_edn8"
                                                                                             id="_ednref8">[8]</a></p>
      <p>V roku 1975 bol ruský pravoslávny biskup Nikodim (rodným menom Boris Georgievič Rotov), metropolita Leningradu,
        zvolený za jedného zo šiestich predsedov SRC. Nikodim, dlhodobý agent KGB, pôsobil na tejto pozícii tri roky, do
        svojej smrti v roku 1978.<a href="#_edn9" id="_ednref9">[9]</a></p>
      <p>Ďalším víťazstvom bolo zvolenie bulharského komunistického špióna Todora Sabeva za zástupcu generálneho
        tajomníka SRC v roku 1979. Sabev tam pôsobil do roku 1993.</p>
      <p>Keď vieme, že východoeurópski komunisti infiltrovali a zmanipulovali cirkvi, nie je ťažké pochopiť prečo SRC v
        januári 1980, napriek odporu svojich členov, trvala na tom, že finančne podporí Zimbabwskú africkú národnú úniu
        – Vlasteneckú frontu (ZANU–PF). ZANU-PF je notoricky známa skupina komunistických partizánov, o ktorých sa vie,
        že vraždili misionárov a zostrelili komerčné lietadlo.</p>
      <p>SRC bola infiltrovaná aj Čínskou komunistickou stranou prostredníctvom Čínskej kresťanskej rady. Čínska
        kresťanská rada je jediným oficiálnym predstaviteľom komunistickej Číny v SRC a kvôli finančným a iným vplyvom
        SRC po dlhé roky postupovala v súlade so záujmami ČKS. Generálny tajomník SRC začiatkom roka 2018 oficiálne
        navštívil Čínu a stretol sa s niekoľkými kresťanskými organizáciami, ktoré sú ovládané ČKS, vrátane Čínskej
        kresťanskej rady, Vlasteneckého hnutia trojitej autonómie protestantských cirkví Číny a Štátnej správy pre
        náboženské záležitosti. V Číne je počet členov neoficiálnych kresťanských skupín (podzemných cirkví) oveľa väčší
        ako tých oficiálnych, napriek tomu delegáti SRC nezorganizovali stretnutie so žiadnymi neoficiálnymi
        kresťanskými skupinami, aby sa vyhli konfliktom s Pekingom.</p>
      <h3>b) Obmedzovanie náboženstva</h3>
      <p>Infiltrácia komunistickým duchom je v západnej spoločnosti všadeprítomná. Náboženstvo je podkopávané
        ideológiami a správaním, ktoré očierňujú Boha. Myšlienky ako „odluka cirkvi od štátu“ a „politická korektnosť“,
        ktoré pramenia z ľavicovej ideológie, sa používajú na vytláčanie a sabotovanie spravodlivých, pravoverných
        náboženstiev.</p>
      <p>Spojené štáty boli založené ako „jeden národ pred Bohom“. Všetci prezidenti USA skladajú prísahu tak, že
        položia ruku na Bibliu a požiadajú Boha, aby požehnal Amerike. Keď dnes ľudia kritizujú správanie, myšlienky a
        zákony, ktoré sa odchyľujú od božských meradiel, alebo keď vystupujú proti potratom či homosexualite, útočia na
        nich komunisti či militantná ľavica v USA. Používajú koncept „odluky cirkvi od štátu“ na to, aby vyhlasovali, že
        náboženstvo by nemalo mať nič spoločné s politikou, a takto hľadajú spôsoby, ako obmedziť Božiu vôľu a uvoľniť
        štandard ľudského správania, ustanovený nebesami.</p>
      <p>Po tisíce rokov dávajú Božské bytosti o sebe vedieť ľuďom, ktorí majú vieru. Veriaci ľudia so skutočnou vierou
        tvorili v minulosti v spoločnosti väčšinu a mali veľmi dobrý vplyv na spoločenskú morálku. Dnes môžu ľudia
        hovoriť o Božej vôli len v kostole. Mimo neho nemôžu snahy o podkopanie kritérií, ktoré Boh pre ľudí stanovil,
        kritizovať ani sa im brániť. Náboženstvo takmer stratilo svoju úlohu v udržiavaní mravnosti v spoločnosti a
        výsledkom je, že morálka ľudí v USA sa rúti dole ako lavína.</p>
      <p>V posledných rokoch dosiahlo vyzdvihovanie politickej korektnosti takú úroveň, že v krajine, ktorá bola
        postavená na kresťanstve, sa ľudia zdráhajú povedať „Veselé Vianoce“. A došlo k tomu len preto, že niektorí
        tvrdia, že to nie je politicky korektné a zraňuje to pocity ľudí, ktorí nie sú kresťanského vyznania. Podobne,
        keď ľudia otvorene hovoria o svojej viere v Boha alebo o modlitbe k Bohu, niektorí to označujú za diskrimináciu
        voči ľuďom s inou vierou či neveriacim. Faktom je, že všetci ľudia majú právo vyjadriť svoju vieru, vrátane úcty
        k svojmu Bohu, svojím vlastným spôsobom, a nemá to nič spoločné s diskrimináciou.</p>
      <p>Teraz sa už v školách nesmú učiť pravoverné náboženstvá ani tradičné hodnoty. Učitelia by nemali hovoriť o
        stvorení, pretože existencia Boha nie je vedecky dokázaná. Veda však doteraz nepreukázala ani ateizmus a
        evolúciu – tieto teórie sa však na školách učia ako skutočnosť.</p>
      <p>Prenikanie ducha komunizmu do spoločnosti a obmedzovanie a manipulovanie náboženstva, kultúry, vzdelávania,
        umenia a práva je nesmierne komplexnou a systémovou vecou.</p>
      <h2>3. Prekrútená teológia komunizmu</h2>
      <p>V minulom storočí sa cez rôzne pokrútené náboženské doktríny vmiešali do náboženstva mnohé komunistické
        myšlienky, ktoré podkopali duchovenstvo a infiltrujú a nepozorovane ničia pravoverné náboženstvá. Kňazi
        nehanebne vykladajú sväté písma podľa svojich nálad, čím skresľujú pravé učenia, ktoré tu zanechali osvietené
        bytosti týchto pravoverných náboženstiev. Obzvlášť v 60. rokoch minulého storočia, „revolučná teológia“,
        „teológia nádeje“, „politická teológia“ a ďalšie pokrútené náboženské doktríny presýtené marxistickými
        myšlienkami, zasiali v náboženských kruhoch chaos.</p>
      <p>Mnoho pastorov z Latinskej Ameriky sa v minulom storočí vzdelávalo na európskych seminároch a boli hlboko
        ovplyvnení novými teologickými teóriami, ktoré boli pozmenené komunistickými tendenciami. V 60. až 80. rokoch
        20. storočia bola v Latinskej Amerike rozšírená tzv. „teológia oslobodenia“. Jej hlavným predstaviteľom bol
        peruánsky kňaz Gustavo Gutiérrez.</p>
      <p>Táto náboženská doktrína vniesla triedny boj a marxistické myslenie priamo do náboženstva a interpretovala
        súcit Boha voči ľudstvu tak, že chudobní by mali byť oslobodení. Veriaci by teda mali byť súčasťou triedneho
        boja, aby chudobní získali rovnaké spoločenské postavenie. Táto myšlienková škola zneužila pokyn, ktorý dal Boh
        Mojžišovi, aby vyviedol Židov z Egypta, ako teoretický základ k viere, že kresťanstvo by malo oslobodiť
        chudobných.</p>
      <p>Teológiu oslobodenia veľmi oceňoval kubánsky komunistický vodca Fidel Castro. Aj keď sa katolícka cirkev týmto
        novovznikajúcim doktrínam v minulosti bránila, nový pápež menovaný v roku 2013 pozval 12. mája 2015 na tlačovú
        konferenciu do Vatikánu Gutiérreza ako hlavného hosťa. Tým jasne ukázal, že súčasná katolícka cirkev ticho
        súhlasí s teológiou oslobodenia a podporuje ju.</p>
      <p>V rôznych kútoch sveta sa začali objavovať mnohé náboženské doktríny podobné teológii oslobodenia, ako
        napríklad „teológia oslobodenia čiernych“, „feministická teológia“, „liberálna teológia“, „queer teológia“ a
        dokonca „teológia smrti Boha“. Tieto pokrútené doktríny veľmi vážne poškodili katolicizmus, kresťanstvo a ďalšie
        pravoverné náboženstvá po celom svete.</p>
      <p>V 70. rokoch minulého storočia vodca neslávne známeho „Chrámu ľudu učeníkov Krista“ (v skratke „Chrámu ľudu“),
        Jim Jones, ktorý sa vyhlásil za prevtelenie Lenina, založil doktrínu svojho kultu na pôvodnom učení
        marxizmu-leninizmu a myšlienkach Mao Ce-tunga. Tvrdil, že ľudí v USA obracia na vieru, aby mohol dosiahnuť svoje
        komunistické ideály. Potom, čo zavraždil amerického kongresmana Lea Ryana, ktorý vyšetroval obvinenia proti
        tejto sekte, Jones vedel, že bude pre neho ťažké uniknúť spravodlivosti, a tak kruto prinútil svojich
        nasledovníkov, aby spáchali masovú samovraždu. Dokonca sám zabil tých, ktorí s ním odmietli samovraždu spáchať.
        Výsledkom týchto samovrážd a vrážd bolo viac ako 900 obetí. Táto sekta vážne podkopala povesť náboženských
        skupín a nepriaznivo ovplyvnila spravodlivú vieru ľudí v pravoverné náboženstvá. Mala teda na Američanov celkovo
        vážny negatívny dopad.</p>
      <h2>4. Náboženský chaos</h2>
      <p>V knihe <em>Nahý komunista (The Naked Communist)</em>, ktorá vyšla v roku 1958, sa uvádza 45 cieľov, ktoré si
        komunisti stanovili, aby splnili svoj cieľ – zničiť USA. Napodiv sa už väčšinu bodov podarilo naplniť. Číslo 27
        v zozname znie: „Infiltrovať sa do cirkví a zjavené náboženstvá nahradiť ,sociálnym’ náboženstvom.
        Zdiskreditovať Bibliu. ...“<a href="#_edn10" id="_ednref10">[10]</a></p>
      <p>Po tisícky rokov bývali náboženstvá pre západný svet dôležitou základňou, ale za posledné desiatky rokov duch
        komunizmu pokrivil túto duchovnú inštitúciu na nepoznanie. Tri pravoverné náboženstvá – kresťanstvo,
        katolicizmus a judaizmus (spoločne označované ako zjavené náboženstvá), boli pozmenené a ovládané duchom
        komunizmu. Tieto náboženstvá tak stratili úlohu, ktorú mali vo svojich pôvodných formách. Nové denominácie,
        ktoré vznikli alebo boli démonicky pokrivené podľa princípov a konceptov komunizmu, sa stali ešte
        priamočiarejšou hlásnou trúbou komunistickej ideológie.</p>
      <p>V dnešných cirkevných kruhoch hlásajú mnohí biskupi a kňazi pokrivené doktríny, zatiaľ čo morálne kazia svojich
        nasledovníkov a spolčujú sa s nimi v nekonečnej sérii škandálov.&nbsp; Mnohí veriaci chodia do kostola skôr zo
        zvyku alebo dokonca preto, že je to pre nich určitá forma zábavy či spoločenského vyžitia, než kvôli opravdivému
        odhodlaniu kultivovať svoj charakter a priblížiť sa k Bohu.</p>
      <p>Náboženstvá sa pokazili zvnútra. Výsledkom je, že ľudia stratili k náboženstvám dôveru a spolu s tým svoju
        spravodlivú vieru v Boha. Následkom toho sa opustili svoju vieru. Ak ľudia neveria v nebesá, nebesá ich nebudú
        ochraňovať a nakoniec bude ľudstvo zničené.</p>
      <p>Keďže doktrína bola pozmenená a na posvätnosť viery sa útočí zvnútra aj zvonka, dokonca i kňazi sa oddávajú
        odporným praktikám a ďalej tak podrývajú integritu cirkvi.</p>
      <p>V roku 2020 sa v americkom denníku <em>The</em> <em>Boston Globe</em> objavila séria správ o sexuálnom
        zneužívaní detí katolíckymi kňazmi v USA. Vyšetrovanie odhalilo, že za niekoľko posledných desaťročí bolo iba v
        Bostone skoro 250 kňazov, ktorí zneužívali deti. Namiesto toho, aby cirkev informovala políciu, pokúsila sa to
        ututlať tým, že týchto „duchovných“ premiestnila do iných oblastí. Kňazi potom pokračovali v zneužívaní detí v
        nových oblastiach, čo malo za následok ešte viac obetí.</p>
      <p>Podobné odhalenia sa rýchlo začali objavovať po celom USA a týkali sa i kňazov v iných katolíckych krajinách,
        vrátane Írska, Austrálie a ďalších. Ostatné náboženské skupiny začali verejne odsudzovať skazenosť
        rímskokatolíckej cirkvi.</p>
      <p>Tlak verejnosti nakoniec donútil Jána Pavla II., aby usporiadal vo Vatikáne konferenciu pre kardinálov a
        prehovoril o týchto škandáloch. Po konferencii prehlásil, že administratívna štruktúra cirkvi sa bude reformovať
        a že kňazi, ktorí sa dopustili sexuálnych deliktov, budú vylúčení. Do dnešných dní cirkev zaplatila okolo dve
        miliardy dolárov v rámci kompenzácie za tieto zneužívania.</p>
      <p>Korupcia v náboženstve je častá v iných kresťanských denomináciách a v iných náboženstvách po celom svete. V
        Číne je náboženstvo kontrolované ČKS a je náchylné na rovnaké prestupovanie zákona, aké sa vyskytuje v celom
        štáte. Mnísi a taoistickí kňazi premenili náboženstvo na biznis a nekontrolovateľne spreneverili peniaze
        veriacich tým, že zneužili ich vieru v Budhov a tradičné čínske božstvá. Poplatky za náboženské obrady a
        pobožnosti spojené s pálením vonných tyčiniek sa môžu vyšplhať až na desiatky tisíc dolárov.</p>
      <p>Bolo postavených mnoho kostolov a chrámov, ktoré vyzerajú na povrchu skvostne, ale spravodlivej viery ubudlo.
        Učeníkov, ktorí sa opravdivo kultivujú, je čoraz ťažšie nájsť. Mnohé chrámy a kostoly sa stali miestom, kde
        prebývajú zlí duchovia, a z chrámov v Číne sa stali komerčné turistické miesta, kde mnísi dostávajú mzdu a
        budhistickí a taoistickí opáti sa stali výkonnými riaditeľmi.</p>
      <p>Päť náboženstiev, ktoré sú v Číne schválené stranou, bolo premenených na organizácie, ktoré majú narušiť
        pôvodné viery a slúžiť ateistickej ideológii strany. Budhizmus v Číne stratil svoj charakter komunity pre
        duchovnú kultiváciu. Je plný politikov v mníšskom rúchu, ktorí velebia ČKS a považujú ju za svojho Boha.</p>
      <p>Podpredseda Čínskej budhistickej asociácie ohľadom správy o 19. zjazde ČKS povedal: „Správa z 19. zjazdu je
        súčasné budhistické sväté písmo a ja sám som ju trikrát ručne prepísal.“ Dodal tiež: „ČKS je dnešným Budhom a
        Bódhisattvou a správa 19. zjazdu je súčasným čínskym budhistickým svätým písmom a žiari lúčmi viery
        komunistickej strany.“ Ďalší mnísi vyzývali budhistických veriacich, aby nasledovali príklad podpredsedu a tiež
        s oddaným srdcom ručne prepisovali správu z 19. zasadania, aby mohli zažiť osvietenie.<a href="#_edn11"
                                                                                                 id="_ednref11">[11]</a>
      </p>
      <p>Po viac ako tisíc rokov boli biskupi po celom svete priamo menovaní alebo schvaľovaní Vatikánom. Okolo 30
        čínskych biskupov uznaných Vatikánom v predošlých rokoch ČKS neuznala. Rovnako tak Vatikán a jemu lojálni
        veriaci v Číne (obzvlášť veriaci podzemnej cirkvi) neuznali biskupov menovaných stranou. Avšak kvôli neustálemu
        nátlaku a pobádaniam strany pápež v roku 2018 uznal sedem biskupov menovaných ČKS, ktorí boli predtým Vatikánom
        exkomunikovaní. Kritici veria, že tento krok, ktorý dáva totalitnému režimu moc nad cirkvou, ustanoví nebezpečný
        precedens, ktorý by mohol ovplyvniť zbytok sveta. Cirkev je komunitou veriacich, ktorej účelom je umožniť
        veriacim sa kultivovať, pozdvihnúť svoju morálku, priblížiť sa k Bohu a nakoniec sa vrátiť do neba. Keď sa v
        ľudskom svete uzatvárajú dohody s diablom, ktorý sa búri proti Bohu a ČKS môže menovať biskupov a vziať si tak
        na starosti záležitosti týkajúce sa desiatok miliónov katolíkov v Číne, ako sa na takúto vec bude pozerať Boh?
        Aká bude budúcnosť desiatok miliónov čínskych katolíkov?</p>
      <p>Duch komunizmu vytvoril v Číne zradný politický systém, ktorý pomocou masových vrážd a teroru zničil tradičnú
        kultúru a rozdrvil vieru. Perzekúcie a ničenie tradícií ateistickou ČKS sú zamerané na násilné pretrhnutie
        väzieb medzi ľuďmi a nebesami a uvrhli Čínu do morálneho úpadku.</p>
      <p>Na Západe a v ďalších častiach sveta použil duch podvod a infiltráciu na zničenie a očiernenie spravodlivých
        náboženstiev, čo ľudí zmiatlo a zviedlo ich na scestie. Následkom toho opustili pravú vieru. Vo svojej vzbure
        proti Bohu sa duch komunizmu správa ako diabol, ktorý vládne nášmu svetu. Pokiaľ ľudstvo bude naďalej strácať
        povedomie o svojom spojení s nebesami, duch získa nad ľuďmi ešte väčšiu kontrolu, až nakoniec nezostane žiadna
        nádej na spásu.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p><a href="#_ednref1" id="_edn1">[1]</a> Karl Marx, citácia podľa: Dimitry V. Pospielovsky, <em>A</em> <em>History
        of Marxist-Leninist Atheism and Soviet Antireligious Policies: History of Soviet Atheism in Theory and Practice,
        and the Believer, Vo. 1 (London: Palgrave Macmillan, 1987), </em>80.</p>
      <p><a href="#_ednref2" id="_edn2">[2]</a> US Library of Congress, „Translation of Letter from Lenin“, Revelations
        from the Russian Archives, stránka navštívená 17.&nbsp;apríla 2020,&nbsp;<a
          href="https://www.loc.gov/exhibits/archives/trans-ae2bkhun.html"></a><a
          href="https://www.loc.gov/exhibits/archives/trans-ae2bkhun.html">https://www.loc.gov/exhibits/archives/trans-ae2bkhun.html</a>.
      </p>
      <p><a href="#_ednref3" id="_edn3">[3]</a> Patriarcha Alexij II.,citácia podľa: Nathaniel Davis (prekl.),&nbsp;A
        Long Walk to Church: A&nbsp;Contemporary History of Russian Orthodoxy&nbsp;(Oxford: Westview Press, 1994), 89.
      </p>
      <p><a href="#_ednref4" id="_edn4">[4]</a> Choekyi Gyaltsen (Čhökji Gjalcchän), 10.&nbsp;pančenláma, citácia podľa:
        Central Tibetan Administration: Department of Information and International Relations,&nbsp;From the Heart of
        the Panchen Lama&nbsp;(Dharamsala, India: Central Tibetan Administration, 2003 edition), stránka navštívená
        17.&nbsp;apríla 2020,&nbsp;<a
          href="http://tibet.net/wp-content/uploads/2015/04/FROM-THE-HEART-OF-THE-PANCHEN-LAMA-1998.pdf"></a><a
          href="http://tibet.net/wp-content/uploads/2015/04/FROM-THE-HEART-OF-THE-PANCHEN-LAMA-1998.pdf">http://tibet.net/wp-content/uploads/2015/04/FROM-THE-HEART-OF-THE-PANCHEN-LAMA-1998.pdf</a>.
      </p>
      <p><a href="#_ednref5" id="_edn5">[5]</a> Tsering Woeser,&nbsp;Forbidden Memory: Tibet During the Cultural
        Revolution,&nbsp;Susan T. Chen, prekl., Robert Barnett, ed. (Lincoln, NE: Potomac Books, apríl 2020).</p>
      <p><a href="#_ednref6" id="_edn6">[6]</a> Kongres USA, Snemovna, Committee on Un-American
        Activities,&nbsp;Investigation of Communist Activities in the New York City Area,&nbsp;83rd Cong., 1st sess.,
        8.&nbsp;júla 1953.&nbsp;<a
          href="https://archive.org/stream/investigationofcnyc0708unit/investigationofcnyc0708unit_djvu.txt"></a><a
          href="https://archive.org/stream/investigationofcnyc0708unit/investigationofcnyc0708unit_djvu.txt">https://archive.org/stream/investigationofcnyc0708unit/investigationofcnyc0708unit_djvu.txt</a>.
      </p>
      <p><a href="#_ednref7" id="_edn7">[7]</a> Momchil Metodiev,&nbsp;Between Faith and Compromise: The Bulgarian
        Orthodox Church and the Communist State (1944–1989) (Sofia: Institute for Studies of the Recent Past/Ciela,
        2010).</p>
      <p><a href="#_ednref8" id="_edn8">[8]</a> Christopher Andrew, „KGB Foreign Intelligence from Brezhnev to the
        Coup“, v: Wesley K. Wark, ed.,&nbsp;Espionage: Past, Present, Future?&nbsp;(London: Routledge, 1994), 52.</p>
      <p><a href="#_ednref9" id="_edn9">[9]</a> Metodiev, „Between Faith.“</p>
      <p><a href="#_ednref10" id="_edn10">[10]</a> W. Cleon Skousen,&nbsp;The Naked Communist („Nahý
        komunista“)&nbsp;(Salt Lake City: Ensign Publishing Co., 1958).</p>
      <p><a href="#_ednref11" id="_edn11">[11]</a> „Zhongguo Fojiao xiehui fuhuizhang: ,Shijiu Da Baogao shi dangdai
        Fojing Wo yijing shouchao san bianʻ“ 中國佛教協會副會長：十九大報告是當代佛經 我已手抄三遍 („Podpredseda Čínskej budhistickej asociácie: ,
        Správa z 19. zjazdu je súčasné budhistické sväté písmo a ja sám som ju trikrát ručne prepísal.ʻ“), Stand News,
        13.&nbsp;decembra 2017. (v čínštine)</p>
      <Link to={`/kapitola_07`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


